.site-tools {
    display: none;
}
@include md-up {
    .site-tools {
        position: fixed;
        display: block;
        z-index: $zindex-popover;
        right: $vertical-spacing;
        bottom: $vertical-spacing;
        border: none;

        .hide-wrapper {
            display: flex;
            transition: all 0.3s;
            &.hide {
                transform: translateY(100%);
                opacity: 0;
            }

            > .active {
                z-index: $zindex-popover;
            }
        }

        .prompt {
            position: relative;
            margin-left: 0.75rem;
        }

        .overlay {
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.5);
        }

        .box {
            position: absolute;
            bottom: 100%;
            margin-bottom: $vertical-spacing;
            right: 0;
            @include popover-box-styles;
        }
        button {
            @include popover-box-button;
        }
    }
}
