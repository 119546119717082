.wp-block-owid-additional-information {
    margin-bottom: 2rem;
    h3 {
        @include h3-style;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        transition: background-color 0.2s;
        &:hover {
            cursor: pointer;
            background-color: $primary-color-100;
            svg {
                margin-right: $padding-x-md;
            }
        }
        svg {
            margin-right: $padding-x-sm;
            transition: all 0.2s;
        }
    }
    &.open {
        h3 {
            background-color: $primary-color-100;
            svg {
                transform: rotate(90deg);
            }
        }
    }

    &[data-variation="merge-left"] {
        @include block-shadow;
        @include left-media-columns;
        h3 {
            padding: 1.5rem $padding-x-sm;
            @include md-up {
                padding: 1.5rem $padding-x-md;
            }
        }
        .content-wrapper {
            @include wrapper-x-spacing;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            background-color: $primary-color-100;
        }
    }

    &[data-variation="full-width"] {
        @include sm-only {
            @include full-width;
        }
        padding: 0 $padding-x-sm;
        background-color: $primary-color-100;
        @include md-up {
            margin-left: -#{$padding-x-md};
            margin-right: -#{$padding-x-md};
            padding: 0 $padding-x-md;
        }
        h3 {
            padding: 1.5rem 0;
        }
        .content {
            padding-bottom: 1.5rem;
        }
    }
}
