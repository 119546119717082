.collapsibleList {
    white-space: nowrap;

    ul {
        list-style: none;
        > .list-item {
            &.visible,
            &.moreButton {
                display: inline-block;

                padding-left: 15px;
            }
        }
    }

    .moreButton {
        cursor: pointer;
    }
}
