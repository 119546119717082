.DownloadTab .download-csv {
    align-items: center;
    text-align: center;

    .btn {
        color: #fff;
        background-color: #0275d8;
        border-color: #0275d8;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 1rem;
        font-size: 1em;
        line-height: 1.5;
        border-radius: 0.25rem;
        text-decoration: none;

        :hover {
            color: #fff;
            background-color: #025aa5;
            border-color: #01549b;
        }
    }
}

.DownloadTab {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 2em;

    p {
        font-size: 0.9rem;
    }

    > a > div {
        display: flex;
    }

    .img-downloads {
        display: flex;
        flex-direction: row;
        justify-content: center;

        a {
            color: #333;
            text-decoration: none;
            margin: 0 1rem 1rem;
            margin-bottom: 1rem;

            div {
                display: flex;
                flex-flow: column;
                align-items: center;
            }

            img {
                margin: 1rem 1rem 0.5rem 1rem;
            }

            aside {
                margin: 0 2rem 0;

                h2 {
                    margin-bottom: 0;
                    text-align: center;
                    font-size: 1.5rem;
                }
            }
        }

        > a:hover > div {
            text-decoration: underline;
        }
    }

    &.mobile {
        .img-downloads {
            margin-bottom: 2rem;
        }

        a {
            margin: 0;

            aside {
                margin: 0;

                h2 {
                    font-size: 1.2rem;
                }
            }
        }

        p {
            font-size: 0.7rem;
        }
    }
}
