/* Population Growth & Vital Statistics */
.owid-data li:nth-of-type(1) h4,
#topics-dropdown li.category:nth-of-type(1) > a,
.population-color {
    color: $population-color;
}
.owid-data li:nth-of-type(1) .link-container {
    border-top: 2px solid $population-color;
}
.owid-data li:nth-of-type(1) a:hover,
#topics-dropdown li.category:nth-of-type(1) .subcategory-menu {
    background: $population-color !important;
}

/* Health */
.owid-data li:nth-of-type(2) h4,
#topics-dropdown li.category:nth-of-type(2) > a,
.health-color {
    color: $health-color;
}
.owid-data li:nth-of-type(2) .link-container {
    border-top: 2px solid $health-color;
}
.owid-data li:nth-of-type(2) a:hover,
#topics-dropdown li.category:nth-of-type(2) .subcategory-menu {
    background: $health-color !important;
}

/* Food & Agriculture */
.owid-data li:nth-of-type(3) h4,
#topics-dropdown li.category:nth-of-type(3) > a,
.food-color {
    color: $food-color;
}
.owid-data li:nth-of-type(3) .link-container {
    border-top: 2px solid $food-color;
}
.owid-data li:nth-of-type(3) a:hover,
#topics-dropdown li.category:nth-of-type(3) .subcategory-menu {
    background: $food-color !important;
}

/* Resources & Energy */
.owid-data li:nth-of-type(4) h4,
#topics-dropdown li.category:nth-of-type(4) > a,
.energy-color {
    color: $energy-color;
}
.owid-data li:nth-of-type(4) .link-container {
    border-top: 2px solid $energy-color;
}
.owid-data li:nth-of-type(4) a:hover,
#topics-dropdown li.category:nth-of-type(4) .subcategory-menu {
    background: $energy-color !important;
}

/* Environmental Change */
.owid-data li:nth-of-type(5) h4,
#topics-dropdown li.category:nth-of-type(5) > a,
.environment-color {
    color: $environment-color;
}
.owid-data li:nth-of-type(5) .link-container {
    border-top: 2px solid $environment-color;
}
.owid-data li:nth-of-type(5) a:hover,
#topics-dropdown li.category:nth-of-type(5) .subcategory-menu {
    background: $environment-color !important;
}

/* Technology & Infrastructure */
.owid-data li:nth-of-type(6) h4,
#topics-dropdown li.category:nth-of-type(6) > a,
.technology-color {
    color: $technology-color;
}
.owid-data li:nth-of-type(6) .link-container {
    border-top: 2px solid $technology-color;
}
.owid-data li:nth-of-type(6) a:hover,
#topics-dropdown li.category:nth-of-type(6) .subcategory-menu {
    background: $technology-color !important;
}

/* Growth & Distribution of Prosperity */
.owid-data li:nth-of-type(7) h4,
#topics-dropdown li.category:nth-of-type(7) > a,
.growth-inequality-color {
    color: $growth-inequality-color;
}
.owid-data li:nth-of-type(7) .link-container {
    border-top: 2px solid $growth-inequality-color;
}
.owid-data li:nth-of-type(7) a:hover,
#topics-dropdown li.category:nth-of-type(7) .subcategory-menu {
    background: $growth-inequality-color !important;
}

/* Economic Development, Work & Standard of Living */
.owid-data li:nth-of-type(8) h4,
#topics-dropdown li.category:nth-of-type(8) > a,
.work-life-color {
    color: $work-life-color;
}
.owid-data li:nth-of-type(8) .link-container {
    border-top: 2px solid $work-life-color;
}
.owid-data li:nth-of-type(8) a:hover,
#topics-dropdown li.category:nth-of-type(8) .subcategory-menu {
    background: $work-life-color !important;
}

/* The Public Sector & Economic System */
.owid-data li:nth-of-type(9) h4,
#topics-dropdown li.category:nth-of-type(9) > a,
.public-sector-color {
    color: $public-sector-color;
}
.owid-data li:nth-of-type(9) .link-container {
    border-top: 2px solid $public-sector-color;
}
.owid-data li:nth-of-type(9) a:hover,
#topics-dropdown li.category:nth-of-type(9) .subcategory-menu {
    background: $public-sector-color !important;
}

/* Global Interconnections */
.owid-data li:nth-of-type(10) h4,
#topics-dropdown li.category:nth-of-type(10) > a,
.global-connections-color {
    color: $global-connections-color;
}
.owid-data li:nth-of-type(10) .link-container {
    border-top: 2px solid $global-connections-color;
}
.owid-data li:nth-of-type(10) a:hover,
#topics-dropdown li.category:nth-of-type(10) .subcategory-menu {
    background: $global-connections-color !important;
}

/* War & Peace */
.owid-data li:nth-of-type(11) h4,
#topics-dropdown li.category:nth-of-type(11) > a,
.war-peace-color {
    color: $war-peace-color;
}
.owid-data li:nth-of-type(11) .link-container {
    border-top: 2px solid $war-peace-color;
}
.owid-data li:nth-of-type(11) a:hover,
#topics-dropdown li.category:nth-of-type(11) .subcategory-menu {
    background: $war-peace-color !important;
}

/* Political Regimes */
.owid-data li:nth-of-type(12) h4,
#topics-dropdown li.category:nth-of-type(12) > a,
.politics-color {
    color: $politics-color;
}
.owid-data li:nth-of-type(12) .link-container {
    border-top: 2px solid $politics-color;
}
.owid-data li:nth-of-type(12) a:hover,
#topics-dropdown li.category:nth-of-type(12) .subcategory-menu {
    background: $politics-color !important;
}

/* Violence & Rights */
.owid-data li:nth-of-type(13) h4,
#topics-dropdown li.category:nth-of-type(13) > a,
.violence-rights-color {
    color: $violence-rights-color;
}
.owid-data li:nth-of-type(13) .link-container {
    border-top: 2px solid $violence-rights-color;
}
.owid-data li:nth-of-type(13) a:hover,
#topics-dropdown li.category:nth-of-type(13) .subcategory-menu {
    background: $violence-rights-color !important;
}

/* Education & Knowledge */
.owid-data li:nth-of-type(14) h4,
#topics-dropdown li.category:nth-of-type(14) > a,
.education-color {
    color: $education-color;
}
.owid-data li:nth-of-type(14) .link-container {
    border-top: 2px solid $education-color;
}
.owid-data li:nth-of-type(14) a:hover,
#topics-dropdown li.category:nth-of-type(14) .subcategory-menu {
    background: $education-color !important;
}

/* Media & Communication */
.owid-data li:nth-of-type(15) h4,
#topics-dropdown li.category:nth-of-type(15) > a,
.media-color {
    color: $media-color;
}
.owid-data li:nth-of-type(15) .link-container {
    border-top: 2px solid $media-color;
}
.owid-data li:nth-of-type(15) a:hover,
#topics-dropdown li.category:nth-of-type(15) .subcategory-menu {
    background: $media-color !important;
}

/* Culture, Values & Society */
.owid-data li:nth-of-type(16) h4,
#topics-dropdown li.category:nth-of-type(16) > a,
.culture-color {
    color: $culture-color;
}
.owid-data li:nth-of-type(16) .link-container {
    border-top: 2px solid $culture-color;
}
.owid-data li:nth-of-type(16) a:hover,
#topics-dropdown li.category:nth-of-type(16) .subcategory-menu {
    background: $culture-color !important;
}
