.faq-page {
    .article-content > section {
        margin-top: 0 !important;
        padding-top: 0 !important;
    }

    .article-content > section > h2 {
        font-size: 1.75rem;
        text-align: left;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }
}
