$chart-border-radius: 2px;
// Match chart styles
$chart-box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;
$zindex-dropdown: 100;

.EntityListContainer {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.EntityList {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-shadow: $chart-box-shadow;
    border-radius: $chart-border-radius;
    background-color: #fff;
    margin-top: 10px;

    &.isDropdown {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: $zindex-dropdown;
    }
}

.ClearSelectionButton {
    color: $controls-color;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    padding: 5px;
}

.EntitySearchResults {
    overflow-y: scroll;
}

.EntityPicker {
    display: flex;
    flex-direction: column;
}

.MetricSettings {
    display: flex;
    align-items: center;
    margin: 10px 0 0;

    .mainLabel {
        flex: 0;
        white-space: nowrap;
        font-size: 14px;
        color: $primary-color-700;
        margin-right: 8px;
    }

    .metricDropdown {
        flex: 1;
        font-size: 14px;
        font-weight: 700;
        color: $primary-color;
    }

    .sort {
        margin-left: 8px;
        flex: 0;
        cursor: pointer;
    }
}

.EntityPickerOption {
    display: flex;
    align-items: center;
    padding: 10px;
    line-height: 1.3;
    border-bottom: 1px solid $primary-color-200;
    font-size: 15px;
    cursor: pointer;
    color: $text-color;
    background-color: $primary-color-50;
    user-select: none;
    position: relative;

    &.selected {
        background-color: #fff;
        font-weight: bold;
        z-index: 1;
    }

    &.focused {
        background-color: $primary-color-30;
    }

    .input-container {
        flex: 0;
        margin-right: 6px;
        align-items: center;
    }

    .info-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .labels-container {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .name {
        flex: 1;
    }

    mark {
        font-weight: 600;
        background-color: #f9eec6;
        border-radius: 2px;
    }

    .color-marker-container {
        position: absolute;
        top: 10px;
        left: 0;
        bottom: 11px;
        display: flex;
        align-items: center;
    }

    .color-marker {
        width: 5px;
        height: 100%;
    }

    .metric {
        flex: 0;
        font-size: 14px;
        font-weight: 400;
        color: $primary-color-500;
        text-align: right;
    }

    .plot {
        position: absolute;
        left: 0;
        right: 5px;
        top: 0;
        bottom: 0;
        pointer-events: none;
        z-index: 0;
    }

    .bar {
        height: 100%;
        background-color: rgba($primary-color, 0.03);
        border-right: 1px solid rgba($primary-color, 0.05);
    }

    &.selected .bar {
        background-color: rgba($primary-color, 0.05);
        border-right: 1px solid rgba($primary-color, 0.07);
    }
}

.MissingData {
    opacity: 0.4;
}

.EntityPickerSearchInput {
    flex: 0;
    position: relative;
    color: $primary-color-800;

    .input-field {
        width: 100%;
        border: none;
        background-color: #fff;
        border-radius: 3px;
        border: none;
        box-shadow: $light-shadow;
        padding: 11px 10px;
        padding-left: 2em;
        outline: none;
        color: inherit;
        font-weight: 700;
        position: relative;
        z-index: 1;

        // Mobile Safari ignores box-shadow if we don't reset the appearance.
        -webkit-appearance: none;

        &::placeholder {
            color: inherit;
            transition: opacity 150ms ease;
        }
        &:focus::placeholder {
            opacity: 0.25;
        }

        &.with-done-button {
            padding-right: 4em;
        }
    }

    .search-icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;
        pointer-events: none;
        z-index: 2;
    }

    .done {
        position: absolute;
        top: 0;
        right: 6px;
        bottom: 0;
        display: flex;
        align-items: center;
        z-index: 2;

        button {
            background-color: $primary-color;
            padding: 8px 12px;
            font-size: 14px;
            color: white;
            border-radius: 3px;
            border: none;
        }
    }
}

.mobile-explorer {
    grid-template-columns: auto;
    grid-template-rows: 0fr 0fr 0fr 1fr;

    .EntityList {
        min-height: 150px;
        height: 35vh;
    }

    .ExplorerFigure {
        min-height: 480px;
        @include full-width;
    }
}
