.donate-page {
    padding-bottom: 3rem;

    .page-header {
        margin: 3rem 0 1.5rem;

        h1 {
            font-family: $serif-font-stack;
            font-weight: 400;
            font-size: 2.5rem;
            color: $text-color;
            margin: 0 0 2rem;

            @include sm-only {
                font-size: 2rem;
                margin: 0 0 1.5rem;
            }
        }
    }

    .shaded-box {
        border: 2px solid $primary-color;
        padding: 2rem;

        @include sm-only {
            padding: 1.5rem;
        }
    }
}

.donate-form {
    font-size: 1.125rem;

    fieldset {
        margin: 0 0 1rem;
    }

    h3 {
        font-family: $sans-serif-font-stack;
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.03rem;
        margin: 0 0 0.5rem;
    }

    label {
        font-weight: 700;
    }

    .owid-checkboxes label {
        font-size: 1rem;
        font-weight: 400;
    }

    input[type="radio"] {
        margin-right: 0.25rem;
    }

    button[type="submit"] {
        background-color: $primary-color;
        font-weight: 700;
        color: white;
        border: none;
        padding: 0.8rem 1rem;
    }

    .note {
        font-size: 0.875rem;
        color: $secondary-text-color;
        margin-bottom: 0;
    }

    .error {
        font-size: 0.875rem;
        color: $error-text-color;
    }

    .custom-amount-input {
        background-color: transparent;
        border: none;
        border-bottom: 1px dotted $primary-color;
        width: 4.25rem;
        outline: none;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 700;

        &::placeholder {
            color: $primary-color-400;
            transition: color 150ms linear;
        }

        &:focus::placeholder {
            color: $primary-color-200;
        }
    }
}

.donate-success-page {
    main {
        text-align: center;
    }

    h1 {
        font-size: 3rem;
    }
}
