.wrapper {
    @include wrapper-x-spacing;
    max-width: $wrapper-max-width;
}

.owid-row {
    margin: 0 -1rem;
    display: flex;
    flex-wrap: wrap;
}

.owid-col {
    padding: 0 1rem;
}

.owid-row.owid-spacing--1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.owid-spacing--1 > .owid-col {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.owid-row.owid-spacing--2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
}
.owid-spacing--2 > .owid-col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.owid-row.owid-spacing--3 {
    margin-left: -1rem;
    margin-right: -1rem;
}
.owid-spacing--3 > .owid-col {
    padding-left: 1rem;
    padding-right: 1rem;
}

.owid-row.owid-spacing--4 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}
.owid-spacing--4 > .owid-col {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.owid-row.owid-spacing--5 {
    margin-left: -2rem;
    margin-right: -2rem;
}
.owid-spacing--5 > .owid-col {
    padding-left: 2rem;
    padding-right: 2rem;
}

.owid-row.owid-spacing--6 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}
.owid-spacing--6 > .owid-col {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

@include sm-only {
    .owid-padding-bottom--sm-3 {
        padding-bottom: 1rem;
    }

    .owid-padding-bottom--sm-5 {
        padding-bottom: 2rem;
    }

    .owid-padding-bottom--sm-6 {
        padding-bottom: 2.5rem;
    }
}

.owid-col--auto {
    flex: 1;
}

.owid-col--shrink {
    flex: 0;
    min-width: 1px;
}

.owid-col,
.owid-col--full {
    flex: 0 0 100%;
    max-width: 100%;
}

.owid-col--half {
    flex: 0 0 50%;
    max-width: 50%;
}

.owid-col--third {
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

@include md-up {
    .owid-col--lg-0 {
        flex: 0;
    }

    .owid-col--lg-1,
    .owid-col--lg-auto {
        flex: 1;
    }

    .owid-col--lg-2 {
        flex: 2;
    }

    .owid-col--lg-3 {
        flex: 3;
    }

    .owid-col--lg-shrink {
        flex: 0;
        min-width: 1px;
    }

    .owid-col--lg-full {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .owid-col--lg-1of2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .owid-col--lg-1of3 {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }

    .owid-col--lg-2of3 {
        flex: 0 0 66.66%;
        max-width: 66.66%;
    }
}

.flex-0 {
    flex: 0;
}
.flex-1 {
    flex: 1;
}
.flex-2 {
    flex: 2;
}
.flex-3 {
    flex: 3;
}
.flex-4 {
    flex: 4;
}
.flex-5 {
    flex: 5;
}

.flex-align-center {
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}
