$placeholder-height: 800px;
$chart-border-radius: 2px;
$explorer-grid-gap: 10px;
$explorer-min-width-first-col: 200px;
$explorer-padding: 0.5rem;

#ExplorerContainer {
    min-height: $placeholder-height;
    width: 100%;
    position: relative;
    padding: $explorer-padding;
}

html.IsInIframe #ExplorerContainer {
    height: 100vh;
    min-height: auto !important;
    max-height: none;
    // leave some padding for shadows
    padding: 3px;
}

.ExplorerHeaderBox {
    padding: 8px;
    min-width: $explorer-min-width-first-col;
    background: white;
    border-radius: $chart-border-radius;
    box-shadow: $light-shadow;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .ExplorerTitle {
        font-size: clamp(16px, 1.5vw, 19px);
        line-height: clamp(18px, 1.7vw, 23px);
        font-weight: bold;
        font-family: "Lato";
    }

    .ExplorerSubtitle {
        color: #7a899e;
        font-size: 13px;
    }
}

.explorerContentContainer {
    // liberale approximation of ExplorerShell._isMobile()
    @media (max-width: 849px) {
        @include wrapper-x-spacing;
    }

    @media (min-width: 850px) {
        max-width: $max-width-covid-data-explorer;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 4fr;
        column-gap: $explorer-grid-gap;
        padding: $explorer-padding;

        .sidebar {
            min-width: $explorer-min-width-first-col;
        }
    }
}

html.IsInIframe .Explorer,
.Explorer.is-embed {
    height: 100%;
    min-height: auto !important;
    max-height: none;
    min-width: auto !important;
    max-width: none;
    padding: 0;
}

html.IsInIframe .ExplorerFigure,
.is-embed .ExplorerFigure {
    min-height: auto !important;
    max-height: none;
}

.Explorer {
    margin: 0 auto;
    padding-top: $explorer-grid-gap;
    width: 100%;
    max-width: $max-width-covid-data-explorer;
    height: 90vh;
    max-height: 900px;
    min-height: 480px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 9fr;
    grid-row-gap: $explorer-grid-gap;
    grid-column-gap: $explorer-grid-gap;

    &.HideControls {
        display: block;

        .ExplorerFigure {
            height: 100%;
        }
    }

    .mobile-button {
        color: #fff;
        background-color: #3f9eff;
        text-align: center;
        padding: 0.375rem 1rem;
        line-height: 1.5;
        border-radius: 0.25rem;
    }

    .ExplorerFigure {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 600px;

        // The chart normally leaves 5px padding on the sides for the shadows. In the future we may
        // want to approach this better, but this is a quick and dirty fix for now.
        margin: -2.5px;

        // Remove chart from layout calculation.
        // Since grid/flex layouts size to their contents, having the chart dictate that leads to
        // a very slow adaptations to resizing.
        .GrapherComponent {
            position: absolute;
        }
    }
}
