.homepage-masthead {
    background: url(/images/WorldMapDark2.png) 50% 0 $primary-color;
    background-size: cover;
    text-align: center;
    padding: 2rem 0;
    color: $tertiary-text-color;

    @include md-up {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    h1 {
        font-family: $serif-font-stack;
        font-weight: 400;
        line-height: 1.4;
        font-size: 1.875rem;
        margin: 0 auto 1.5rem;
        max-width: 39.5rem;

        @include sm-only {
            font-size: 1.5rem;
        }
    }

    .see-all {
        font-size: 1.125rem;
        font-weight: 700;
        color: $tertiary-text-color;
        border: 3px solid $tertiary-text-color;
        padding: 0.35rem 0.7rem;
        display: inline-block;
        transition: color 150ms ease, border-color 150ms ease;
        margin-bottom: 1rem;

        &:hover {
            color: $tertiary-color-100;
            border-color: $tertiary-color-100;
        }

        .icon {
            margin-left: 0.6rem;
            transition: transform 200ms ease;
            display: inline-block;
        }

        &:hover .icon {
            transform: translate(0, 2px);
        }
    }

    p {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.3;
        color: $primary-color-400;
        text-align: center;
        margin: 0.25rem 0 0;

        strong {
            color: $primary-color-300;
        }
    }
}

.homepage-masthead--search {
    max-width: 35rem;
    margin: 2.5rem auto 0;

    h2 {
        font-size: 1.125rem;
        font-weight: 700;
        color: $primary-color-500;
        margin: 0 0 0.75rem;

        em {
            font-style: normal;
            color: $primary-color-400;
        }
    }

    .search-input-wrapper {
        position: relative;
        font-size: 1.25rem;

        .icon {
            position: absolute;
            top: 0;
            left: 1.125rem;
            bottom: 0;
            display: flex;
            align-items: center;
            color: $primary-color-400;
            pointer-events: none;
        }

        input {
            font-family: $sans-serif-font-stack;
            font-size: 1.25rem;
            font-weight: 700;
            color: $text-color;
            background-color: white;
            border: none;
            border-radius: 100px;
            transition: box-shadow 150ms ease;
            padding: 1rem 1.5rem 1rem 3rem;
            width: 100%;
            &:focus {
                outline: none;
                box-shadow: 0 0 5px rgba(white, 0.1);
            }

            &::placeholder {
                color: $primary-color-400;
                transition: opacity 150ms ease;
            }

            &:focus::placeholder {
                opacity: 0.5;
            }
        }
    }

    .counts {
        text-align: left;
        font-family: $serif-font-stack;
        max-width: 22rem;
        padding-top: 1rem;
        margin: 0 auto;
        text-align: center;

        .number {
            font-size: 2rem;
            line-height: 1;
            color: $primary-color-200;
        }

        .label {
            font-family: $sans-serif-font-stack;
            text-transform: uppercase;
            color: $primary-color-600;
        }
    }
}

.homepage-featured {
    background-color: $tertiary-color-100;
    padding: 2rem 0;

    h2 {
        font-family: $serif-font-stack;
        font-size: 1.75rem;
        color: black;
        text-align: center;
        margin: 0 0 2rem;
    }

    .list-item {
        display: flex;
        font-family: $serif-font-stack;
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
        border: 2px solid rgba(black, 0.1);
        padding: 0.35rem;
        transition: opacity 200ms ease;

        .icon {
            flex: 0 0 2.5rem;
            text-align: center;
            color: rgba(black, 0.25);
        }

        .label {
            color: rgba(black, 0.7);
            flex: 1;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}

.homepage-coverage {
    padding: 2.5rem 0;

    @include sm-only {
        text-align: center;
    }

    .inner-wrapper {
        margin: 0 auto;
    }

    section {
        margin-bottom: 1.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .divider {
        flex: 1;
        border-left: 2px solid $primary-color-100;
    }

    .lead {
        font-family: $serif-font-stack;
        font-size: 1.5rem;
        line-height: 1.2;
        color: $primary-color-500;
        text-align: center;
        margin: 0;
    }

    .lead--first {
        margin-bottom: 2.5rem;
    }

    .lead--last {
        margin-top: 2.5rem;
    }

    h3 {
        font-size: 0.875rem;
        font-weight: 400;
        color: $primary-color-500;
        text-transform: uppercase;
        letter-spacing: 0.035rem;
        margin: 0 0 0.25rem;
    }

    ul {
        list-style-type: none;
        color: $primary-color-500;
        font-size: 1rem;
    }

    strong {
        font-weight: 700;
        color: $primary-color-800;
    }

    .oxford-logo {
        max-width: 15rem;
    }

    .coverage-link {
        display: block;
        position: relative;

        .hover-note {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            display: flex;
            text-align: center;
            align-items: center;
            align-content: center;
            text-shadow: 0 0 10px white;
            padding: 1rem;
            transition: opacity 350ms ease;

            p {
                font-size: 1.125rem;
                font-weight: 400;
                line-height: 1.45;
                flex: 1;
                color: $primary-color-700;
            }
        }

        img {
            transition: opacity 450ms ease, filter 450ms ease;
            opacity: 0.7;
            width: 100%;
            max-width: 41rem;
            margin: 0 auto;
            display: block;
        }

        &:hover {
            img {
                filter: blur(3px);
                opacity: 0.2;
            }
            .hover-note {
                opacity: 1;
            }
        }
    }
}

.homepage-posts {
    background-color: $primary-color-100;

    h2 {
        @include posts-heading;
    }

    .see-all {
        display: flex;
        justify-content: center;
        a {
            font-size: 1.125rem;
            padding: 0.75rem;
            font-weight: 700;
            color: $primary-color-700;
            background-color: transparent;
            border: 2px solid $primary-color-300;
            transition: color 150ms ease, background-color 150ms ease,
                border-color 150ms ease;

            &:hover {
                color: $primary-color-900;
                background-color: $primary-color-300;
                border-color: $primary-color-300;
            }
        }

        .icon,
        .label {
            display: inline-block;
        }

        .icon {
            margin-left: 0.5rem;
        }
    }
}

.homepage-posts--explainers {
    flex: 1;
    padding: 1.5rem 0 1.75rem;

    ul {
        @include posts-list;
    }

    @include lg-up {
        padding: 2.5rem 0 2.75rem;
    }
}

.homepage-subscribe {
    padding: 3rem 0;
    margin: 0;

    h2 {
        font-family: $serif-font-stack;
        font-size: 1.5rem;
        margin: -0.5rem 0 1rem;
    }
}

.homepage-subscribe--social-media {
    .shaded-box {
        flex: 1;
        padding: 2rem;
        background-color: $primary-color-100;
        color: $text-color;
    }

    .list-item {
        color: $primary-color-700;
        display: flex;
        align-items: center;
        transition: opacity 200ms ease;

        .icon {
            font-size: 1.375rem;
            flex: 0;
            min-width: 2.1rem;
        }

        .label {
            font-size: 1rem;
            font-weight: 700;
            flex: 1;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}

.homepage-projects {
    padding: 3rem 0;
    background-color: $primary-color-100;
    text-align: center;

    @include md-up {
        text-align: left;
    }

    .wrapper {
        @include content-wrapper;
    }

    h2 {
        font-family: $serif-font-stack;
        font-size: 2rem;
        text-align: center;
        margin: 0 0 2rem;
    }

    h3 {
        font-family: $serif-font-stack;
        font-size: 1.5rem;
        margin: 0 0 0.5rem;
    }

    p {
        font-size: 1.125rem;
        color: $primary-color-500;
        margin: 0;
    }

    .list-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 1.5rem;
        margin-bottom: 1rem;
        color: $text-color;
        border: 2px solid $primary-color-300;
        transition: border-color 150ms ease;

        &:hover {
            border-color: $primary-color-400;
        }

        @include md-up {
            align-items: center;
            flex-direction: row;
        }

        .icon-left,
        .icon-right {
            flex: 0;
        }

        .content {
            flex: 1;
        }

        .icon-left {
            flex: 0 0 4rem;
            max-width: 5rem;
            text-align: center;
            align-self: center;
            margin-bottom: 1rem;

            @include md-up {
                margin-right: 1.5rem;
                margin-bottom: 0;
            }

            img,
            svg {
                width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
            }
        }

        .icon-right {
            @include sm-only {
                display: none;
            }
            font-size: 1.25rem;
            color: $primary-color-400;
            margin-left: 1rem;
        }
    }
}

.homepage-entries {
    .wrapper {
        margin-bottom: 8rem;
    }

    .category-wrapper {
        margin-top: 3rem;
        @include md-up {
            margin-top: 5rem;
        }
    }

    h3 {
        margin: 0 0 1.5rem;
        font-family: $serif-font-stack;
        line-height: 1.3;
        font-size: 2rem;
        @include md-up {
            font-size: 4rem;
            margin-bottom: 2rem;
        }
    }
    h4 {
        margin: 2rem 0 1rem;
        font-size: 0.9rem;
        color: $primary-color-400;
    }

    .category-entries {
        display: grid;
        grid-template-columns: repeat(
            auto-fill,
            minmax($grid-card-min-width, 1fr)
        );
        gap: 0.5rem;

        .entry-item-container {
            position: relative;
            display: block;
            padding: 1rem;

            @include md-up {
                padding: 1.5rem;
                transition: box-shadow 300ms ease;

                &:after {
                    position: absolute;
                    content: "";
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    border: 1px solid currentColor;
                    opacity: 0;
                    transition: opacity 300ms ease;
                }

                &:hover {
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2),
                        0 4px 10px rgba(0, 0, 0, 0.15);
                    &:after {
                        opacity: 1;
                    }
                    .entry-item {
                        svg {
                            margin-right: -0.2rem;
                        }
                    }
                }
            }
        }
    }

    .entry-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        line-height: 1.2;

        &:after {
            content: "";
            background-color: currentColor;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            opacity: 0.05;
        }

        .excerpt,
        .kpi {
            color: $primary-color;
        }

        .excerpt {
            margin: 0.2rem 0 1rem;
            font-weight: lighter;
            font-size: 0.9rem;
        }

        .kpi {
            font-size: 1.4rem;
            margin-bottom: $vertical-spacing;
            ul {
                list-style: none;
            }
            li {
                display: inline;
            }
            p {
                margin: 0;
                &:nth-of-type(2) {
                    margin-top: 0.75rem;
                    font-size: 1rem;
                }
            }
        }

        h5 {
            margin: 0;
            font-size: 1rem;
            text-align: right;
            span {
                white-space: nowrap;
            }
            svg {
                margin-left: 0.5rem;
                font-size: 0.8rem;
                vertical-align: initial;
                transition: margin 300ms ease;
            }
        }
    }
}
