.cookie-notice {
    background-color: $tertiary-color;
    font-size: 0.9375rem;
    line-height: 1.5;
    padding: 1rem 0;
    text-align: center;
    margin-top: 2rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $zindex-cookie-notice;
    box-shadow: 0 0 30px rgba(#fff, 0.3);

    // when closed
    display: none;

    @keyframes slide-in {
        0% {
            opacity: 0; // prevent flickering when animation starts
            transform: translate(0, 100%);
        }
        1% {
            opacity: 1;
        }
        100% {
            transform: translate(0, 0);
        }
    }

    &.open {
        display: block;
        animation: 400ms ease slide-in forwards;
    }

    @include md-up {
        font-size: 1rem;
        text-align: left;
    }

    .wrapper {
        max-width: 65rem;
    }

    p {
        font-weight: 700;
        margin: 0;
    }

    .actions {
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        margin-top: 1rem;

        @include md-up {
            margin-top: 0;
        }
    }

    .button {
        font-size: inherit;
        font-weight: 700;
        font-size: 0.9375rem;
        display: block;
        color: $text-color;
        line-height: 1.2;
        margin: 0;
        padding: 0.6rem 0.8rem;
        cursor: pointer;
        border: 2px solid rgba($text-color, 0.2);
        transition: border-color 150ms linear;

        flex: 1;

        @include md-up {
            flex: 0;
            font-size: 1rem;
        }

        margin-left: 0.75rem;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            border-color: rgba($text-color, 0.8);
        }

        .icon {
            margin-right: 0.5rem;
        }
    }

    .button.accept {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
        transition: background-color 150ms linear, border-color 150ms linear;

        &:hover {
            background-color: $primary-color-900;
            border-color: $primary-color-900;
        }
    }
}
