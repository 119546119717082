/*******************************************************************************
 * Spacing
 */

$padding-x-sm: 1.5rem;
$padding-x-md: 2rem;
$vertical-spacing: 1rem;

/*******************************************************************************
* Sizes
*/

$mobile-header-wrapper-height: 3.5rem;
$mobile-header-border-height: 0.25rem;
$mobile-header-height: $mobile-header-wrapper-height +
    $mobile-header-border-height;

$content-max-width: 50rem;
$sidebar-content-width: 14rem;
$sidebar-padding-top: 2rem;
$sidebar-toggle-width: 2rem;
$sidebar-closed-drawer-width: 0;
$text-min-content-width: 30rem;
$text-max-content-width: 40rem;
// NB: the side by side layout depends on
// $graph-min-content-width < $text-max-content-width + $graph-max-content-width
$graph-min-content-width: 44rem;
$graph-max-content-width: 50rem;

$wrapper-max-width: $text-min-content-width + $graph-min-content-width + 3 *
    $padding-x-md;

$grid-card-min-width: 20rem;

/*******************************************************************************
* Breakpoints
*/

//md
$md-min-width: 55rem;
// lg: graphs and text shown side by side.
$lg-min-width: $text-min-content-width + $graph-min-content-width + 3 *
    $padding-x-md + $sidebar-closed-drawer-width;
// xlg: md + graphs shown side by side
$xlg-min-width: $text-max-content-width + $graph-max-content-width + 3 *
    $padding-x-md + $sidebar-closed-drawer-width;
// xxlg: xlg + permanent sidebar
$xxlg-min-width: $text-max-content-width + $graph-max-content-width + 5 *
    $padding-x-md + $sidebar-content-width;

@mixin sm-only {
    @media (max-width: $md-min-width - 0.0625rem) {
        @content;
    }
}

@mixin md-up {
    @media (min-width: $md-min-width) {
        @content;
    }
}

@mixin lg-up {
    @media (min-width: $lg-min-width) {
        @content;
    }
}

@mixin lg-only {
    @media (min-width: $lg-min-width) and (max-width: $xlg-min-width - 0.0625rem) {
        @content;
    }
}

// Blocks are shown side-by-side from that breakpoint up (when applicable)

@mixin xlg-only {
    @media only screen and (min-width: $xlg-min-width) and (max-width: $xxlg-min-width - 0.0625rem) {
        @content;
    }
}

@mixin xlg-up {
    @media only screen and (min-width: $xlg-min-width) {
        @content;
    }
}

@mixin xxlg-down {
    @media only screen and (max-width: $xxlg-min-width - 0.0625rem) {
        @content;
    }
}

// The sidebar is shown from that breakpoint up
@mixin xxlg-up {
    @media only screen and (min-width: $xxlg-min-width) {
        @content;
    }
}

/*******************************************************************************
 * Fonts
 */

$sans-serif-font-stack: Lato, "Helvetica Neue", Arial, sans-serif;
$serif-font-stack: "Playfair Display", Georgia, "Times New Roman", serif;

/*******************************************************************************
 * Z-index
 */

$zindex-input: 1;
$zindex-global-entity-select: 11;
$zindex-site-header: 12;
$zindex-footnote: 15;
$zindex-sidebar: 20;
$zindex-popover: 100;
$zindex-search-overlay: 100;
$zindex-cookie-notice: 110;
$zindex-nav-dropdown: 120;
$zindex-tooltip: 1070;
$zindex-lightbox: 99998;
$zindex-adminbar: 99999;

/*******************************************************************************
  * Coronavirus (COVID-19)
  */

$max-width-covid-data-explorer: 75rem;
