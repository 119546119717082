.controlsRow {
    ul {
        margin: 0px;
        padding: 0px;
    }

    .list-item {
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;

        /* blue gray */
        color: #60758c;
    }

    .moreButton {
        /* blue medium */
        color: #0073e6;
    }

    input[type="checkbox"] {
        height: 10px;
        width: 10px;
    }

    margin: 12px 0px 0px 0px;
}
