.TimelineComponent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    padding: 0 1em;

    > div {
        margin-right: 0.5em;
        &:last-child {
            margin-right: 0;
        }
    }

    .date {
        font-size: 0.9rem;
        margin-right: 0;
    }

    .play,
    .date {
        transition: color 0.1s;
        &:hover {
            color: $controls-color;
        }
    }

    $handle-diameter: 16px;
    .slider {
        height: 4px;
        flex-grow: 1;
        background: #adb6c0;
        border-radius: 5px;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: $handle-diameter;
        margin-right: $handle-diameter;

        padding: 12px 0;
    }

    .handle {
        $handleSidePadding: 6px;

        position: absolute;
        z-index: 1;
        margin-left: (-$handle-diameter / 2) - $handleSidePadding;
        cursor: col-resize;
        padding: 10px $handleSidePadding;

        > .icon {
            height: $handle-diameter;
            width: $handle-diameter;
            border-radius: 100%;

            background: #ffffff;
            border: 1.5px solid $controls-color;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
            z-index: 1;
            pointer-events: none;
        }
    }

    .interval {
        position: absolute;
        height: 6px;
        background: $controls-color;

        cursor: grab;
        &:active {
            cursor: grabbing;
        }

        padding: 10px 0;
    }

    .handle,
    .interval,
    .slider {
        box-sizing: content-box;
        background-clip: content-box;
    }
}
