.NoData {
    background-color: rgba(#fff, 0.6);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .action {
        background-color: $controls-color;
        border-radius: 2px;
        padding: 8px 10px;
        color: #fff;
    }
}
