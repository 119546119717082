$zindex-ControlsFooterMenu: 3;
$zindex-ControlsFooter: 2;

.SelectEntitiesButton {
    color: $controls-color;

    svg {
        margin-right: 3px;
    }
}

.ChangeEntityButton {
    color: $controls-color;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        transform: scale3d(8, 8, 8);
        opacity: 0;
    }

    40% {
        transform: scale3d(0.8, 0.8, 0.8);
        opacity: 1;
    }

    60% {
        transform: scale3d(1.25, 1.25, 1.25);
    }

    80% {
        transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.relatedQuestion {
    font-size: 12px;
    height: 20px;
    padding: 0 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e1e1e1;

    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: underline;
        }
    }

    a,
    svg {
        color: $controls-color;
    }
    svg {
        margin-left: 0.4rem;
        font-size: 0.8em;
        vertical-align: unset;
    }
}

$footerRowHeight: 32px; // keep in sync with Controls.tsx's footerRowHeight

.ControlsFooter {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: $zindex-ControlsFooter;
    color: #777;

    .footerRowSingle,
    .footerRowMulti {
        box-sizing: content-box;
        width: 100%;
        height: $footerRowHeight;
    }

    .footerRowSingle > * {
        width: 100%;
    }

    .footerRowMulti {
        display: flex;

        .inline-controls {
            flex-grow: 1;
        }

        nav.tabs {
            min-width: 50%;
        }
    }

    .extraControls {
        display: flex;
        height: 100%;
        align-items: center;
        font-size: 0.8em;
    }

    .footerRowMulti .extraControls {
        padding-left: 0.5em;
    }

    .footerRowSingle .extraControls {
        justify-content: center;
    }

    .extraControls label {
        margin: 0;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .extraControls button {
        margin: 0;
        font-size: 1em;
        color: $controls-color;
    }

    nav.tabs ul {
        margin: 0;
        padding: 0;
        display: flex;
        width: 100%;
        text-align: center;
    }

    nav.tabs li {
        flex-grow: 1;
        list-style-type: none;
        border-left: 1px solid #e1e1e1;
        height: $footerRowHeight;

        &.download-tab-button {
            svg {
                margin-right: 0.15em;
            }
        }
    }

    .footerRowSingle nav.tabs li:first-child {
        border-left: none;
    }

    nav.tabs li > a {
        display: block;
        text-transform: uppercase;
        color: #777;
        font-size: 0.8em;
        height: $footerRowHeight - 1;
        line-height: $footerRowHeight;
        border-bottom: 2px solid rgba(0, 0, 0, 0);
        background-color: $primary-color-30;
        transition: color 0.1s;
        padding: 0 0.6em;
    }

    nav.tabs li > a:hover {
        color: rgba(0, 33, 71, 0.9);
        text-decoration: none;
    }

    nav.tabs li.active > a {
        color: rgba(0, 33, 71, 0.9);
        border-bottom-color: currentColor;
        background-color: unset;
    }

    .inline-controls,
    nav li {
        border-top: 1px solid #e1e1e1;

        &.active {
            border-top-color: transparent;
        }
    }
}
