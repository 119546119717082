.wp-block-owid-summary {
    @include block-spacing;

    border: 1px solid $primary-color;
    border-top-width: 5px;
    color: $primary-color;
    background-color: #f9f4e4;

    h2 {
        margin-top: 0;
        font-family: $serif-font-stack;
        font-size: 2rem;
        text-align: center;
        color: inherit;
    }
    li {
        margin-bottom: 0.5rem;
        a {
            display: block;
            color: inherit;
            transition: all 150ms;
            &:hover,
            &:visited {
                color: inherit;
            }
            &:hover::after {
                color: $secondary-color;
            }
            &::after {
                display: inline-block;
                content: "\2193\a0\a0 jump to section";
                margin-left: 0.5rem;
                font-size: 0.8rem;
                color: $primary-color-400;
            }
        }
    }
}
