html {
    line-height: 1.6;
    font-weight: 400;
    font-family: $sans-serif-font-stack;
    text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
}

body {
    background-color: $body-bg;
    color: $text-color;
    margin: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: $sans-serif-font-stack;
}

/* Typographical Elements
--------------------------------------------- */

::-moz-selection {
    background-color: #4979d4; /* helles blau*/
    color: #fff;
}

::selection {
    background-color: #4979d4;
    color: #fff;
}

a {
    color: #1059e5;
    text-decoration: none;
    cursor: pointer;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

b,
strong {
    font-weight: 700;
}

cite,
em,
i {
    font-style: italic;
}

/* ----------------------------------------------------------- */

h6 {
    color: #000;
    line-height: 1.2;

    margin-bottom: 0px;
    margin-top: 10px;

    font-size: 15px;
    font-weight: 400;
    font-style: italic;
    opacity: 0.8;
    text-align: center;
}

h6 a.ref sup {
    font-size: 12px !important;
}

/* Images
--------------------------------------------- */

img {
    max-width: 100%;
    height: auto;
}

/* iFrames
--------------------------------------------- */

iframe {
    border: none;
}

/* MISPY: This styling is for mainly for the old non-grapher nvd3 stuff. */

.narrow_iframe {
    display: block;
    margin: 0 auto;
    max-width: 750px;
}

address {
    padding-left: 8px;
    padding-top: 7px !important;
    margin-top: -15px !important;
    margin-bottom: 30px;

    font-family: $sans-serif-font-stack;
    font-style: normal !important;
    line-height: 14px !important;
    font-size: 10px !important;

    color: #1059e5;
    background-color: #fafafa;
    text-align: left;
}

.narrow_iframe address {
    margin-top: -10px !important;
}

address a {
    color: #1059e5;
    padding-right: 30px;
}

address a:hover {
    color: #ff4012;
}

/* Box Sizing
--------------------------------------------- */

* {
    box-sizing: border-box;
}

/* Hide reCAPTCHA badge.
 * This means we have to manually write a notice.
---------------------------------------------------- */
.grecaptcha-badge {
    display: none;
}

/* Colors
--------------------------------------------- */

.has-bluish-grey-color {
    color: $bluish-grey-text-color;
}
