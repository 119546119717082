/* Page Navigation Sidebar
--------------------------------------------- */
.toc-wrapper {
    @include xxlg-down {
        height: 2rem; // HACK, prevent "Contents" button to run over content when page hasn't been scrolled yet.
        margin-bottom: 1rem;
    }
    @include xxlg-up {
        height: 100%;
    }
}

.entry-sidebar {
    width: $sidebar-content-width + 2 * $padding-x-md;
    z-index: $zindex-sidebar;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background-color: $white;

    &.sticky {
        background: $primary-color-100;
    }

    .entry-toc {
        top: 0;
        height: 100vh;
        position: sticky;
        line-height: 1.3em;
        padding: $vertical-spacing 0;
        overflow-y: auto;
    }

    li {
        list-style-type: none;
        &.section {
            margin-top: $vertical-spacing;
            color: #111;
            font-weight: 700;
        }

        &.subsection {
            font-size: 0.8rem;
        }

        a {
            display: block;
            color: $grey-text-color;
            font-size: 0.9rem;
            border-left: 0.5rem solid transparent;
            padding: $vertical-spacing/4 $padding-x-md;

            &:hover {
                background-color: $primary-color-200;
            }
        }

        &.active a {
            color: $primary-color;
            background-color: $primary-color-200;
            border-left-color: $primary-color;
        }

        &:first-child a {
            font-size: 1rem;
            background: none;
            color: $grey-text-color;
            border-left-color: transparent;

            &:hover {
                color: $primary-color;
            }
        }
    }

    @include xxlg-down {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-left: -($sidebar-content-width + 2 * $padding-x-md) + $sidebar-closed-drawer-width;
        @include sm-only {
            margin-left: -($sidebar-content-width + 2 * $padding-x-md);
        }
        transition: margin 300ms ease;

        .toggle-toc {
            position: absolute;
            top: 0;
            bottom: 0;
            padding: $vertical-spacing 0;
            left: $sidebar-content-width + 2 * $padding-x-md;
            margin-left: $padding-x-sm;
            pointer-events: none;
            @include md-up {
                margin-left: $padding-x-md;
            }
            button {
                @include popover-box-button;
                z-index: $zindex-sidebar;
                position: sticky;
                top: $vertical-spacing;
                pointer-events: auto;
                white-space: nowrap;
            }
        }

        ul {
            // Hide ToC content for smoother looking transition
            display: none;
        }

        &.toggled {
            margin-left: 0;
            @include block-shadow;

            .toggle-toc {
                margin-left: -1rem;
                @include sm-only {
                    svg {
                        margin: 0 0.25rem;
                    }
                    .label {
                        display: none;
                    }
                }
            }

            ul {
                display: block;
            }
        }
    }

    @include xxlg-up {
        position: relative;
        height: 100%;
        background-color: initial;

        .sticky-sentinel {
            position: absolute;
            height: 1px;
            width: inherit;
        }

        .toggle-toc {
            display: none;
        }
    }
}
