$chart-border-radius: 2px;

.ExplorerControlBar {
    $option-color: #7a899e;
    $selected-option-color: #243d60;

    display: flex;
    justify-content: space-between;
    background: white;
    border-radius: $chart-border-radius;
    box-shadow: $light-shadow;
    padding: 0 14px;

    .ExplorerControl {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        color: $option-color;
        max-width: 22%;
        margin-right: 10px;

        padding-top: 11px;
        padding-bottom: 4px;

        &:last-child {
            margin-right: 0;
        }

        .HiddenControlHeader {
            // Don't show hidden label in desktop but preserve space for it so options are aligned.
            opacity: 0;
        }

        input {
            margin-right: 5px;
        }

        .ControlHeader {
            font-size: 11px;
            margin-bottom: 8px;
            text-transform: uppercase;
        }

        .ControlOption {
            line-height: 1.3;
        }

        .ControlOption + .ControlOption {
            margin-top: 8px;
        }

        .UnavailableOption {
            opacity: 0.5;
            cursor: not-allowed;
        }

        .SelectedOption {
            color: $selected-option-color;
        }

        &.count {
            width: 15%;
        }
    }
}

.mobile-explorer {
    .ExplorerControlBar {
        .mobile-button {
            margin: 12px 0;
            display: block;
        }

        flex-direction: column;
        position: fixed;
        left: 0;
        right: 0;
        z-index: 100;
        border-radius: 0;
        padding-top: 10px;

        .HiddenControlHeader {
            // Remove hidden labels on mobile entirely
            display: none;
        }

        &.show-controls-popup {
            bottom: 0;
            animation-name: slideUp;
            animation-duration: 0.5s;
            transition-timing-function: cubic-bezier();
            animation-iteration-count: 1;
            box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
        }

        &.hide-controls-popup {
            bottom: -150%;
            animation-name: slideDown;
            animation-duration: 0.5s;
            transition-timing-function: cubic-bezier();
            animation-iteration-count: 1;
        }
    }

    .ExplorerControl {
        max-width: unset;

        padding-top: 0;
        padding-bottom: 0;
        margin-right: 0;

        &.count {
            width: unset;
        }

        .intervalDropdown {
            width: 100%;
        }
    }

    .ExplorerControl + .ExplorerControl {
        padding-top: 15px;
    }

    .ExplorerDropdown {
        width: 100%;
    }
}

.ExplorerDropdown {
    font-size: 13px;
    font-weight: 400;
    width: 200px;
    max-width: 100%;
}

@keyframes slideUp {
    0% {
        bottom: -150%;
    }

    100% {
        bottom: 0;
    }
}

@keyframes slideDown {
    0% {
        bottom: 0%;
    }

    100% {
        bottom: -150%;
    }
}
