.post-card {
    height: 100%;
    a {
        display: flex;
        flex-direction: column;
        height: inherit;
        transition: all 0.2s;
        color: inherit;
        background-color: $white;
        border: 1px solid $primary-color-300;

        &:hover {
            background-color: $primary-color-30;
            @include block-shadow;
        }
    }

    .cover-image {
        width: 100%;
        height: 0;
        padding-bottom: 52.5%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: rgba(white, 0.05);
    }

    h3 {
        margin: 0;
        font-size: 1.3rem;
        line-height: 1.2;
        font-weight: 400;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 1.25rem;
    }

    .excerpt {
        margin-top: $vertical-spacing;
        font-weight: lighter;
        line-height: 1.2;
    }

    .entry-meta {
        margin-top: $vertical-spacing;
        margin-bottom: 0;
        font-size: 14px;
    }

    .authors {
        font-weight: bold;
    }
}
