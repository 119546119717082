.StandaloneGrapherOrExplorerPage main figure[data-grapher-src],
#fallback {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    // The landscape values)
    $orientation-width: 680px;
    $orientation-height: 480px;
    $ideal-ratio: $orientation-width / $orientation-height;

    $max-width: 1250px; // The rough max-width across all pages on the site
    $max-height: $max-width / $ideal-ratio;

    // Charts shouldn't be below this height in any circumstance, whether mobile or not.
    // Except in iframes, where we override this.
    min-height: 480px;

    // On small viewports always use the full available height.
    // We only leave space for the mobile header and fill the rest.
    height: calc(100vh - #{$mobile-header-height});

    @include md-up {
        // On larger viewports, we want to leave more space at the bottom to make the entry link
        // more visible. It doesn't need to be fully visible, just enough to pop out to the user.
        height: calc(100vh - 175px);
    }

    // At this point, the ratio-preserving sizing kicks in, so we want to mirror that in the CSS
    // to avoid using unnecessary space.
    @media (min-width: $orientation-width) {
        // We no longer use the full height
        max-height: #{1 / $ideal-ratio * 100}vw;
    }

    @media (min-width: $max-width) {
        max-width: $max-width;
        max-height: $max-height;
    }

    // Needed so the absolutely-positioned <LoadingIndicator> on /grapher pages is centered
    // inside <figure>
    position: relative;
}

#fallback {
    // Override any sizing rules set by the above code, so the fallback chart never overlaps the
    // site header or other site elements
    height: auto !important;
    max-width: none !important;
    max-height: none !important;

    margin-top: 25px;
}

#fallback > img {
    max-width: 100%;
    border: 1px solid #ccc;
}

.StandaloneGrapherOrExplorerPage .site-subnavigation {
    padding-left: 1rem;
    margin: 0 auto;
    max-width: $max-width-covid-data-explorer;
}

.StandaloneGrapherOrExplorerPage main {
    .related-research-data {
        @include block-spacing;
        margin-top: $vertical-spacing;
        background-color: $primary-color-100;
        max-width: 800px;
        & > :first-child {
            margin-top: 0;
        }
        .research {
            font-size: 1.1rem;
        }
        svg {
            margin-right: 0.5rem;
        }
        h3 {
            margin-bottom: 0.5rem;
        }
        a:hover {
            text-decoration: underline;
        }
        ul {
            margin-left: 1rem;
        }
    }
}

html.IsInIframe .StandaloneGrapherOrExplorerPage {
    background-color: inherit;

    > main {
        min-height: inherit;
    }

    main figure[data-grapher-src] {
        height: 100vh;
        min-height: auto;
        max-height: none;
        min-width: auto;
        max-width: none;
    }

    .site-header,
    .alert-banner,
    .offset-subnavigation,
    .donate-footer,
    .cookie-notice,
    .site-footer,
    .related-research-data {
        display: none;
    }
}
