.site-footer {
    background-color: $primary-color;
    padding: 3rem 0;
    color: $primary-color-500;

    @include sm-only {
        text-align: center;
        padding: 2rem 0;
    }

    .wrapper {
        @include wrapper-x-spacing;
        max-width: $wrapper-max-width;
    }

    a {
        color: $primary-color-400;
        transition: color 150ms ease;

        &:hover {
            color: $primary-color-300;
        }
    }

    .owid-logo {
        display: block;
        font-size: 1.5rem;
        font-weight: 300;
        text-decoration: none;
        color: $primary-color-700;
        text-align: right;
    }

    ul {
        list-style-type: none;
        margin-bottom: 1rem;

        a {
            display: block;
            text-decoration: none;

            @include sm-only {
                padding: 0.5rem;
            }
        }

        @include sm-only {
            margin-bottom: 2rem;
            border-top: 1px solid $primary-color-800;

            li {
                border-bottom: 1px solid $primary-color-800;
            }
        }
    }

    .legal {
        font-size: 0.875rem;
        color: $primary-color-600;
        text-align: left;

        p {
            margin: 0 0 1rem;
        }
    }

    .partner-logo {
        max-width: 10rem;
        margin-bottom: 0.25rem;
        display: block;
        transition: opacity 150ms ease, filter 150ms ease;
        filter: grayscale(1);
        opacity: 0.5;

        &:hover {
            filter: grayscale(0);
            opacity: 1;
        }

        @include sm-only {
            margin: 0 auto 0.5rem;
        }
    }

    .gcdl-logo {
        float: right;
        width: 2.75rem;
        margin: 0 0 0 1rem;
    }

    .donate-link {
        border: 2px solid $primary-color-600;
        padding: 0.5rem;
    }

    .logos img {
        display: inline-block;
    }
}

.donate-footer {
    background-color: $tertiary-color;
    padding: 1.25rem 0;
    text-align: center;
    margin-top: 2rem;

    @include md-up {
        text-align: left;
    }

    .wrapper {
        @include content-wrapper;
    }

    p {
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0.5rem 0;
    }

    .donate-button {
        display: inline-block;
        font-size: 1.125rem;
        background-color: $secondary-color;

        .icon {
            margin-left: 0.5rem;
        }
    }
}
