.addEntityButton {
    font-weight: 700;
    color: $controls-color;

    animation: appear 500ms cubic-bezier(0, 0, 0.4, 1) 1s;
    animation-iteration-count: initial;
    animation-fill-mode: backwards;

    .icon {
        display: inline-block;
        margin-right: 3px;
        vertical-align: text-bottom;
        position: relative;
        width: 16px;
        height: 16px;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        path {
            stroke: #fff;
            stroke-width: 2px;
        }

        &::before {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 16px;
            height: 16px;
            background-color: $controls-color;
            border-radius: 100%;
            transition: transform 200ms cubic-bezier(0, 0, 0.4, 1);

            animation: bounceIn 750ms cubic-bezier(0, 0, 0.4, 1) 1s;
            animation-iteration-count: initial;
        }
    }

    &:hover .icon::before {
        transform: scale(1.25) !important;
    }
}
