/* Float Clearing
--------------------------------------------- */

.clearfix:before {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
    content: " ";
    display: table;
}

.nowrap {
    white-space: nowrap;
}

.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}
