$cell-border: 1px solid rgba(0, 0, 0, 0.08);

.tableTab {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.95);
    text-align: center;
}

.tableTab {
    border-bottom: $cell-border;
}

.tableTab .data-table {
    th {
        position: sticky;
        z-index: 10;
        white-space: nowrap;

        &.dimension,
        &.entity {
            top: 0;
        }

        &.entity {
            z-index: 11;
        }

        &.subdimension {
            top: 53px;
        }
    }

    .entity {
        position: sticky;
        left: 0;
        z-index: 10;
    }
}

.data-table {
    font-size: 0.9375rem;
    line-height: 16px;
    border: none;
    background: white;
    width: 100%;
    border-spacing: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
        rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;

    thead tr:last-child th,
    th.entity {
        border-bottom: $cell-border;
    }

    tr th:first-child,
    tr td:first-child {
        padding-left: 15px;
    }

    tr th:last-child,
    tr td:last-child {
        padding-right: 15px;
    }

    thead tr:first-child th {
        padding-top: 10px;
        padding-bottom: 8px;
    }

    th {
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: bottom;
    }

    tr:first-child td {
        padding-top: 8px;
    }

    tr:last-child td {
        padding-bottom: 15px;
    }

    th.sortable {
        cursor: pointer;
    }

    th {
        background-color: white;
    }

    th.sortable:hover {
        background-color: rgb(245, 245, 245);
    }

    th,
    td {
        padding-left: 15px;
        padding-right: 15px;
    }

    th.dimension {
        text-align: center;

        > div {
            min-height: 35px;
        }
    }

    th.subdimension {
        border-right: $cell-border;
    }

    .deltaColumn {
        min-width: 145px;
    }

    th.entity,
    td.entity {
        border-right: $cell-border;
    }

    tr > .dimension-start ~ .dimension-start,
    tr > th.dimension ~ th.dimension,
    tr > th.firstSubdimension ~ th.firstSubdimension {
        border-left: $cell-border;
    }

    th.firstSubdimension div,
    th.endSubdimension div {
        min-width: 56px;
    }

    th.lastSubdimension {
        border-right: none;
    }

    th:last-child,
    td:last-child {
        border-right: none;
    }

    td {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    tbody tr {
        padding-top: 20px;
        padding-bottom: 20px;

        td {
            background-color: white;
        }
    }

    tbody tr:nth-child(2n) td {
        background-color: rgba(247, 247, 247);
    }

    tbody tr:hover td {
        background-color: rgba(241, 241, 241);
    }

    .entity {
        text-align: left;
        font-weight: 700;
    }

    .dimension {
        text-align: right;

        .name,
        .unit {
            display: block;
        }

        .unit {
            font-weight: 700;
            opacity: 0.5;
            padding-bottom: 3px;
        }
    }

    .closest-time-notice-icon {
        opacity: 0.5;
        padding-right: 8px;
        font-size: 13px;
        cursor: default;

        .icon {
            font-size: 0.75rem;
            opacity: 0.6;
        }
    }

    .range-time {
        opacity: 0.5;
        font-size: 13px;
        padding-bottom: 4px;
        padding-left: 2px;
    }

    th .sort-icon {
        float: right;
        opacity: 0.15;
        padding-left: 5px;

        &.active {
            opacity: 1 !important;
        }
    }

    th:hover .sort-icon {
        opacity: 0.6;
    }
}

.closest-time-notice-tippy {
    max-width: 13rem;
    text-align: center;
    padding: 0.25rem;
}
