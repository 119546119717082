@import "site/css/variables.scss";
@import "site/css/colors.scss";
@import "site/css/mixins.scss";

$sans-serif-font-stack: Lato, "Helvetica Neue", Arial, sans-serif;
$serif-font-stack: "Playfair Display", Georgia, "Times New Roman", serif;

$primary-color-30: #fafbfd;

// These should be between 0–100 in order to avoid conflicting with
// site dropdowns, search overlays, etc.
$zindex-chart: 1;
$zindex-tab: 1;
$zindex-Tooltip: 20;

.GrapherComponent,
.GrapherComponent p,
.GrapherComponent ul,
.GrapherComponent ol {
    font-family: $sans-serif-font-stack;
}

.GrapherComponent {
    display: inline-block;
    border-bottom: none;
    border-radius: 2px;
    text-align: left;

    line-height: 1em;

    background: white;
    color: #333;

    position: relative;

    /* Hidden overflow x so that tooltips don't cause scrollbars */
    overflow: hidden;

    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 2px 0px,
        rgba(0, 0, 0, 0.25) 0px 2px 2px 0px;
    z-index: $zindex-chart;

    * {
        box-sizing: border-box;
    }

    button {
        background: none;
        border: none;
    }

    .btn {
        font-size: 0.8em;
        white-space: normal;
    }

    .flash {
        margin: 10px;
    }
    .clickable,
    button {
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    .noselect {
        -webkit-user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }
    input[type="checkbox"] {
        cursor: pointer;
    }

    /* Make World line slightly thicker */
    svg .key-World_0 polyline {
        stroke-width: 2 !important;
    }

    .projection .nv-line {
        stroke-dasharray: 3, 3;
    }

    .projection .nv-point {
        fill: #fff;
        stroke-width: 1;
        opacity: 0.5;
    }

    .projection .nv-point.hover {
        stroke-width: 4;
    }

    a {
        cursor: pointer;
        color: #0645ad;
        fill: #0645ad;
        border-bottom: none;
    }

    h2 {
        font-size: 2em;
        margin-top: 0;
        margin-bottom: 0.8em;
        font-weight: 500;
        line-height: 1.1;
    }

    .unstroked {
        display: none;
    }

    .DownloadTab,
    .tableTab,
    .sourcesTab {
        z-index: $zindex-tab;
    }
}

.GrapherComponent.isExportingToSvgOrPng {
    padding: 0 !important;
}

.Tooltip {
    z-index: $zindex-Tooltip;
}

@import "grapher/controls/entityPicker/EntityPicker.scss";
@import "grapher/controls/globalEntitySelector/GlobalEntitySelector.scss";
@import "grapher/controls/CommandPalette.scss";
@import "grapher/controls/ScaleSelector.scss";
@import "grapher/controls/Controls.scss";
@import "grapher/timeline/TimelineComponent.scss";
@import "grapher/controls/ShareMenu.scss";
@import "grapher/noDataModal/NoDataModal.scss";
@import "grapher/controls/CollapsibleList/CollapsibleList.scss";
@import "grapher/controls/ControlsRow.scss";
@import "grapher/captionedChart/CaptionedChart.scss";
@import "grapher/controls/EntitySelectorModal.scss";
@import "grapher/controls/AddEntityButton.scss";
@import "grapher/downloadTab/DownloadTab.scss";
@import "grapher/dataTable/DataTable.scss";
@import "grapher/sourcesTab/SourcesTab.scss";
@import "grapher/mapCharts/MapTooltip.scss";
@import "grapher/sparkBars/SparkBars.scss";
@import "grapher/loadingIndicator/LoadingIndicator.scss";
