.article-content .wp-block-owid-prominent-link {
    svg {
        width: 0.4em;
        height: 1em;
        vertical-align: middle;
        margin-left: 0.6rem;
        transition: all 0.2s;
    }

    background-color: $primary-color-100;
    border: 1px solid $primary-color-100;
    border-radius: 2px;
    transition: all 0.1s;

    &:hover {
        @include block-shadow;
        border: 1px solid $primary-color-400;
        svg {
            margin-left: 0.8rem;
        }
    }

    a {
        @include cancel-link-styles;
    }

    &.is-style-default {
        @include left-media-columns;
        margin-bottom: 2rem;
        a {
            display: block;
            padding: $padding-x-sm;
            @include md-up {
                padding: $padding-x-md;
            }
        }

        h3 {
            @include h3-style;
            margin-top: 0;
        }
    }

    &.is-style-thin {
        background-color: transparent;
        margin-bottom: $vertical-spacing;
        .content-wrapper {
            padding: 0.5rem;
            background-color: $primary-color-100;
        }
        .content {
            font-size: 0.9rem;
            font-weight: lighter;
            line-height: 1.2;
            *:first-child {
                margin-top: 0;
            }
            *:last-child {
                margin-bottom: 0;
            }
        }
        .title {
            margin-top: $vertical-spacing / 4;
            font-weight: bold;
            line-height: 1;
        }

        &.with-image a {
            display: flex;
            align-items: stretch;
            figure {
                flex: 0 0 5rem;
                margin: 0.5rem;
                @include md-up {
                    margin: 0;
                    align-self: center;
                }
                img {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    box-shadow: none;
                }
            }
            .content-wrapper {
                flex: 1;
            }
        }
    }
}
