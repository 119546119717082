.lightbox {
    .container {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: $zindex-lightbox;
        background-color: #001023; // primary-color darkened 50%
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .react-transform-component {
        overflow: visible;
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
    }

    .react-transform-element {
        display: block; // prevent stretching large images on Safari
        & > * {
            max-height: 100vh; // prevent tall images from overflowing
            background: white;
        }
    }

    .tools {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem 0.5rem;
        background-color: rgba(0, 0, 0, 0.7);

        button,
        a {
            padding: 0 1rem;
            font-size: 1.2rem;
            font-weight: bold;
            border: none;
            outline: none;
            color: $white;
            cursor: pointer;
            background: none;
            transition: color 0.3s;
            &:hover {
                color: lightgrey;
            }
        }
        .close {
            transform: rotate(45deg);
        }
    }
}
